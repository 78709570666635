@mixin inputCommonProperties {
    width: 100%;
    height: 40px !important;
    background: #ffffff;
    border-radius: 6px !important;
}

.variableAmountInput,
.variableAmountInput:focus {
    @include inputCommonProperties();
    box-shadow: 0px 0px 0px 2px rgba(24, 144, 255, 0.2) !important;
    border: 1px solid #40a9ff !important;
}

.loginInputBox,
.loginInputBox:focus {
    @include inputCommonProperties();
    border-radius: 0px !important;
    border: 1px solid #d9d9d9 !important;
}
.inputBox {
    @include inputCommonProperties();
    border: 0px;
}

.inputAddOnBefore {
    @include inputCommonProperties();
    border: 0px !important;
    input {
        height: 40px !important;
        border-radius: 6px !important;
    }
    span {
        background-color: #4164ab;
        color: #ffffff;
        cursor: pointer;
        border-color: #4164ab;
        border-radius: 6px !important;
    }
}
.inputBox:disabled {
    color: rgba(83, 83, 83, 0.85) !important;
    opacity: 0.8;
}
