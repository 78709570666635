.fixpageLayout {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
}
.cgbtn {
    box-shadow: none !important;
}
.cgbtn,
.cgbtn:focus,
.cgbtn:hover {
    width: 167px;
    height: 40px;
    background: radial-gradient(
        118.75% 157.07% at 34.74% -18.75%,
        #5278c7 0%,
        #233f78 100%
    );
    box-shadow: -4.75px 11px 30px rgba(35, 63, 120, 0.25);
    border-radius: 12px !important;
    align-self: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 22px;
}

.cgtextbox {
    min-width: 350px;
    width: 98%;
    height: 40px;
    box-sizing: border-box;
    background: #ffffff !important;
    border-radius: 6px !important;
    border: 0px !important;
}

.loginInputBox {
    border-radius: 0px !important;
    border: 1px solid #d9d9d9 !important;
}

.pageBackground {
    background: linear-gradient(
        146.44deg,
        #f2f2ff 12.17%,
        #f4f4fe 21.06%,
        rgba(235, 235, 255, 0.906647) 40.26%,
        rgba(230, 230, 255, 0.32) 58.22%,
        #e9e9ff 82.66%
    );
}

.dummyImage {
    box-sizing: content-box;
    padding: 0px 0 20px 0px;
    width: 168px;
}

.createAccountContainer {
    width: 375px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 5px;
}

.tagLine {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 125.5%;
    text-align: center;
    color: #535353;
    padding: 0px 0px 50px 0px;
}

.createAccount {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 125.5%;
    text-align: center;
    color: #535353;
}
.actionsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
}
.createAccountActionOtp {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: rgba(105, 104, 104, 1);
    margin: 16px 0px 0px 0px;
    width: 100%;
    display: flex;
}
.createAccountAction {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #8f90a6;
    margin: 16px 0px;
}
.disabledResendButton {
    pointer-events: none;
    color: rgba(83, 83, 83, 0.4);
    padding-left: 6px;
}
.inLineAction {
    color: #1890ff;
    cursor: pointer;
    padding-left: 6px;
}
.actionMeta {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 125.5%;
    color: #8f90a6;
}

.pageLayout {
    display: flex;
    flex-direction: column;
    background: linear-gradient(
        146.44deg,
        #f2f2ff 12.17%,
        #f4f4fe 21.06%,
        rgba(235, 235, 255, 0.906647) 40.26%,
        rgba(230, 230, 255, 0.32) 58.22%,
        #e9e9ff 82.66%
    );
    padding: 20px 10px;
    min-height: 100vh;
    position: relative;
}

.deepLinkLanding {
    padding: 0px !important;
}

.categoryIcon {
    width: 99px;
    height: 84px;
}
.categoryIcon:hover {
    cursor: pointer;
}

.right_items {
    display: flex;
    justify-content: center;
}

.info_compoent {
    background: rgba(255, 255, 255, 0.85);
    box-shadow: 0px 2px 8px #eaeaff;
    border-radius: 14.25px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.info_compoent_text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
}

.info_compoent_icon {
    padding: 15px;
}

.center_align {
    justify-content: center;
    align-items: center;
}
.confirmationBtn {
    border-radius: 2px !important;
    width: 66px;
    height: 30px;
    text-shadow: none !important;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016) !important;
}

.confirmationText {
    color: rgba(83, 83, 83, 0.65);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-left: 24px;
}

.confirmationPrimary {
    background: radial-gradient(
        118.75% 157.07% at 34.74% -18.75%,
        #5278c7 0%,
        #233f78 100%
    );
    margin-left: 8px;
    font-family: Roboto;
    outline: none;
    border-radius: 2px;
}

.confirmationSecondary {
    background: #ffffff;
    color: rgba(83, 83, 83, 0.85);
    margin-left: 100px;
    border: 1px solid #d9d9d9;
    font-family: Roboto;
    outline: none;
    border-radius: 2px;
}
.userActions {
    display: flex;
    flex-direction: column;
}
.optionLabel {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 8px;
    color: #535353;
}
.pageDiv {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
}
.pageDiv {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
}
.pageTitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #535353;
    margin-top: 28px;
    margin-bottom: 20px;
}
.alignImages {
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    gap: 15px;
}
.cgtextboxDisabled {
    background: #ffffff !important;
    border-radius: 6px !important;
    border: 0px !important;
    color: rgba(83, 83, 83, 0.85) !important;
    opacity: 0.8;
}
.cgLargebtn {
    width: 197px !important;
}
.cgEditAmoutBox {
    /* width: 100px !important;
    min-width: 100px !important; */
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    border: 1px !important;
}
.cghalftextbox {
    width: 175px !important;
    min-width: 175px !important;
}
.upiAppsLayout {
    justify-content: space-between;
}

.copyIconStyle {
    margin-left: 10px;
    cursor: pointer;
}
.upiqrLayout {
    padding-top: 0px !important;
}

.profileInputFieldWrapper {
    display: flex;
    flex-direction: column;
    gap: 23px;
}

.logoutButtonContainer {
    background: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(124.66px);
    border-radius: 14.25px;
    padding: 16px 24px;
    display: flex;
    cursor: pointer;
    gap: 20px;
    margin-top: 10px;
}

.labelCtaWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profileUpdateCtas {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #1890ff;
    cursor: pointer;
}

.nameWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10%;
}
.feedbackbtn {
    width: 157px !important;
}

.upiRegisteredName {
    font-weight: 400;
    font-size: 12px;
    color: #535353a6;
}
.chipContainer {
    width: 100%;
    display: flex;
    gap: 12px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 15px;
}

.recentTransactionCTA {
    width: 100%;
    text-align: end;
    color: #1890ff;
    text-shadow: 0px 4px 4px rgba(24, 144, 255, 0.1);
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
}

.paymentProgressingWrapper {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.paymentStatusInfo {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
    width: 100%;
    text-align: center;
}

.paymentStatusText {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
}

.paymentProgressText {
    color: #4164ab;
}

.paymentStatusImage {
    width: 300px;
    height: 300px;
}

@media (max-width: 300px) {
    .paymentStatusImage {
        width: 200px;
        height: 200px;
    }
}

.paymentProgressingWrapper {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.paymentStatusInfo {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
    width: 100%;
    text-align: center;
}

.paymentStatusText {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
}

.paymentProgressText {
    color: #4164ab;
}

.paymentStatusImage {
    width: 300px;
    height: 300px;
}

@media (max-width: 300px) {
    .paymentStatusImage {
        width: 200px;
        height: 200px;
    }
}

@media (min-width: 992px) {
    .categoryIcon {
        width: 102px;
        height: 87px;
    }
    .info_compoent {
        width: 90%;
        justify-content: flex-start;
    }
    .info_compoent_text {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 22px;
    }
    .pageContainer {
        background: linear-gradient(
            146.44deg,
            #f2f2ff 12.17%,
            #f4f4fe 21.06%,
            rgba(235, 235, 255, 0.906647) 40.26%,
            rgba(230, 230, 255) 58.22%,
            #e9e9ff 82.66%
        );
    }
    .pageLayout {
        width: 90.5%;
        margin: 0 auto;
        padding-top: 30px;
    }
    .deepLinkLanding {
        padding: 0px !important;
    }
    .createAccountContainer {
        width: 611px;
        background: rgba(255, 255, 255, 0.45);
        box-shadow: 4px 0px 4px #e5e5ff, 0px 4px 4px #e6e6ff;
        border-radius: 14.25px;
        margin-top: 20px;
    }
    .cgbtn,
    .cgbtn:focus,
    .cgbtn:hover {
        width: 375px;
        height: 40px;
        border-radius: 12px !important;
        font-size: 16px !important;
    }
    .cgtextboxDesktop {
        width: 913px !important;
    }
    .cghalftextbox {
        width: 442px !important;
    }
    .cgLargebtnDestop {
        width: 520px !important;
    }
    .cgEditAmoutBox {
        width: 200px !important;
        min-width: 200px !important;
    }
    .fixedHeight {
        max-height: 100vh;
        overflow: hidden;
    }

    .upiLandingHeight {
        height: 100vh;
        min-height: 80vh;
        padding: 0px 10px;
    }
    .createAccount {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: rgba(83, 83, 83, 0.85);
    }
    .tagLine {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 0px 0px 50px 0px;
        color: #535353;
    }
    .dummyImage {
        padding: 20px 0;
    }
    .cgtextbox {
        width: 100%;
        min-width: 380px;
    }

    .amountBifur {
        width: 100% !important;
    }
}
