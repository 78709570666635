@mixin FontStyle($weight, $size, $color) {
    font-family: Roboto;
    font-weight: $weight;
    font-size: $size;
    color: $color;
}
@mixin FlexStyle($justify, $align) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
}
.sideDrawerUserProfile {
    flex-direction: column;
    @include FlexStyle(center, center);
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(222, 222, 255, 0.3) 76.13%
    );
    box-shadow: inset 0px -1px 0px #f8f8ff;
    border-radius: 0px 0px 14.25px 14.25px;
    padding-bottom: 45px;
}
.sideDrawerTagLine {
    @include FontStyle(500, 12px, #535353);
    margin: 20px 0px 10px;
}
.sideDrawerBtn {
    width: 221px !important;
    margin: 8px 0px !important;
}

.createAccountActionSideDrawer {
    @include FontStyle(400, 12px, rgba(83, 83, 83, 0.85));
    margin-top: 10px;
}
.menuList {
    list-style-type: none;
    padding-left: 20px;
    background: rgba(255, 255, 255, 0.3);
}

.itemActive {
    color: #1890ff !important;
}
.menuItem {
    @include FontStyle(normal, 14px, #4164ab);
    @include FlexStyle(space-between, center);
    padding: 9px 20px 9px 0px;
}
.menuItem:hover {
    cursor: pointer;
}
.active {
    color: #1890ff;
}
.disabled {
    color: #4164ab !important;
    pointer-events: none;
    opacity: 0.3;
}
.itemsIcon {
    padding-right: 9px;
}
.openArrow {
    float: right;
}
.sideDrawerUserName {
    @include FontStyle(500, 20px, #4164ab);
    text-transform: capitalize;
}
.sideDrawerPhoneNumber {
    @include FontStyle(normal, 12px, #535353);
}
.menuItemIconWrapper {
    @include FlexStyle(center, center);
    gap: 7px;
}
.emailRow {
    @include FlexStyle(center, center);
    gap: 10px;
}
.emailText {
    @include FontStyle(400, 12px, #535353);
    margin: 0;
}
.emailEditText {
    @include FontStyle(400, 12px, #1890ff);
    margin: 0;
    cursor: pointer;
}
.loginButtonWrapper {
    width: 221px;
}
