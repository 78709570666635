@mixin buttonCommonProperties {
    background: radial-gradient(
        118.75% 157.07% at 34.74% -18.75%,
        #5278c7 0%,
        #233f78 100%
    );
    box-shadow: -4.75px 11px 30px rgba(35, 63, 120, 0.25);
    border-radius: 12px !important;
    align-self: center;
    font-weight: 500;
    font-size: 14px !important;
    font-family: Roboto;
    line-height: 22px;
    max-height: 100%;
}

.smallButton,
.smallButton:focus,
.smallButton:hover {
    @include buttonCommonProperties();
    width: 100%;
    height: 40px !important;
}
