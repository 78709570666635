.carouselItemStyles {
    /* Note: backdrop-filter has minimal browser support */
    display: flex !important;
    border-radius: 14.25px;
    justify-content: center;
    align-items: center;
}
.carouselItemText {
    width: 180px;
    padding-left: 24px;
    color: rgba(255, 255, 255, 0.85);

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    /* CG Style Guide/Light/Elevation 03 */
}

.carouselItemColor_01 {
    background: radial-gradient(
        143.86% 887.35% at -10.97% -22.81%,
        #9b9cf8 0%,
        #8082ed 100%
    );
    box-shadow: 0px 10px 16px rgba(177, 178, 254, 0.5);
    backdrop-filter: blur(124.66px);
}
.carouselItemText_01 {
    text-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04),
        0px 4px 8px rgba(96, 97, 112, 0.16);
}
.carouselItemColor_02 {
    background: #ffffff;
    box-shadow: 0px 4px 17.81px #e3e4ff;
    backdrop-filter: blur(124.66px);
}

.carouselItemText_02 {
    text-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04),
        0px 4px 8px rgba(96, 97, 112, 0.16);
    color: #000000;
}

.carouselItemColor_03 {
    background: radial-gradient(
        143.86% 887.35% at -10.97% -22.81%,
        #feb4c5 0%,
        #db869a 100%
    );
    box-shadow: 0px 10px 16px rgba(239, 160, 179, 0.5);
    backdrop-filter: blur(124.66px);
}
.carouselItemText_03 {
    text-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04),
        0px 4px 8px rgba(96, 97, 112, 0.16);
}

.carouselAnnimation {
    width: 130px;
}
.carouselContainer {
    width: 350px;
}
.carouselImage {
    width: 160px;
}

.home_page_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.loginAvatar {
    width: 54px;
}
.loginBtnShadow {
    box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04),
        0px 8px 16px rgba(96, 97, 112, 0.16);
}
.right_items {
    display: flex;
    align-items: center;
}

.loginBtn {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-transform: capitalize;
    margin-right: 12px;
    /* identical to box height, or 140% */

    /* primary */

    color: #4164ab;
}
.loginBtn:hover {
    cursor: pointer;
}
.loginAvatar:hover {
    cursor: pointer;
}

.tagLine {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    color: #535353;
}

.allCategoryHeading {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 164%;
    color: #535353;
    display: flex;
    align-items: center;
    gap: 10px;
}

.allCategoryItems {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    gap: 10px;
}
.allCategoryItems::after {
    content: '';
    width: 102px;
}

.menuItem {
    margin-top: 20px;
}

.background_color {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.47) 0%,
        rgba(255, 255, 255, 0.47) 0%,
        rgba(222, 222, 255, 0.47) 76.13%
    );
}

.drawer_title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 25px */

    text-align: center;

    /* Black */

    color: #535353;
}

.drawer_title_meta {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    font-weight: 400px;
    line-height: 125.5%;
    color: #555770;
}

.login_drawer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.staticPagesContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    background: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(124.655px);
    border-radius: 14.25px;
    padding: 10px;
}

.staticPagesItem {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 164%;
    /* identical to box height, or 23px */
    color: #4164ab;
}
.userInitials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;

    width: 54px;
    height: 56px;

    background: #f3f3f3;
    opacity: 0.7;
    /* CG Style Guide/Light/Elevation 04 */

    box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04),
        0px 8px 16px rgba(96, 97, 112, 0.16);
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
}
.confirmationTitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;

    color: #535353;
}

.confirmationModalContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
}
.categryHeading {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 125.5%;
    width: 100%;
    color: #535353;
}
.howItWorksContainer {
    width: 100%;
    backdrop-filter: blur(124.66px);
    border-radius: 14.25px;
    display: flex;
    flex-direction: column;
}
.categoryContainer {
    width: 100%;
    background: rgba(255, 255, 255, 0.45);
    border: 1px solid #ebebf0;
    backdrop-filter: blur(124.66px);
    border-radius: 14.25px;
    display: flex;
    flex-direction: column;
    padding: 16px 14px 24px 14px;
    min-height: 226px;
}

.loanCategoryHeaders {
    display: flex;
    flex-direction: column;
    gap: 3%;
}

.checkPointWrapper {
    display: flex;
    gap: 8px;
    align-items: flex-start;
}

.pointers {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #535353;
}

.loanCategoryDiv {
    background: rgba(255, 255, 255, 0.65);
    border-radius: 14px;
    width: 100%;
    display: flex;
    overflow-x: scroll;
    margin-top: 16px;
    gap: 20px;
    align-items: center;
    height: 160px;
    padding-left: 20px;
}

.verticalLine {
    border-left: 1px solid #f0f0f0;
    height: 120px;
    display: flex;
    align-self: center;
}

.checkAndPayWrapper {
    width: 240px;
    height: 115px;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 17.81px #ebecff;
    border-radius: 14.25px;
    overflow: scroll;
}

.checkAndPayWrapperHead {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(255, 255, 255, 0.15) 0%,
        rgba(222, 222, 255, 0.15) 76.13%
    );
    align-self: flex-end;
    cursor: pointer;
    display: flex;
    gap: 8px;
    padding: 7px 9px 7px 10px;
    width: 100%;
    justify-content: flex-end;
}

.checkAndPayWrapperBody {
    display: flex;
    flex-direction: column;
    height: inherit;
    padding: 0px 12px;
    gap: 5px;
}

.checkAndPayText {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;

    color: #1890ff;
}

.billerName {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #535353;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.loanNumber {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: rgba(83, 83, 83, 0.65);
}

.dpdBracket {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    background: radial-gradient(
        143.86% 887.35% at -10.97% -22.81%,
        #feb4c5 0%,
        #db869a 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 10px;
}

.comingSoonCategories {
    background: radial-gradient(
        143.86% 887.35% at -10.97% -22.81%,
        rgba(155, 156, 248, 0.1) 0%,
        rgba(128, 130, 237, 0.1) 100%
    );
    backdrop-filter: blur(124.66px);
    border-radius: 14.25px;
    cursor: not-allowed;
    padding: 0px 8px 18px 8px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    flex-wrap: wrap;
}

.checkPointersDiv {
    width: 100%;
}

.redTag {
    height: 22px;
    background: radial-gradient(
        131.25% 131.25% at 0% -12.5%,
        #ffa39d 0%,
        #f93a2e 100%
    );
    border-radius: 4px;
    font-weight: 700;
    font-size: 11px;
    line-height: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 112px;
}

@media (max-width: 390px) {
    .comingSoonCategories {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 700px) {
    .comingSoonCategories {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (min-width: 992px) {
    .redTag {
        font-size: 14px;
    }

    .checkPointersDiv {
        width: 35%;
    }

    .comingSoonCategories {
        display: flex;
        padding: 0px 28px 28px 28px;
        gap: 20px;
    }

    .categoryContainer {
        padding: 16px 18px 24px 18px;
    }
    .loanCategoryDiv {
        width: 68%;
        margin-top: -30px;
        height: 170px;
    }
    .loanCategoryHeaders {
        flex-direction: row;
    }

    .checkAndPayWrapper {
        gap: 5%;
        overflow: scroll;
    }

    .checkAndPayWrapperHead {
        padding: 5px 9px 5px 10px;
    }
    .carouselContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        overflow: scroll;
        margin-top: 40px;
    }
    .carouselItemStyles {
        width: 297px;
        height: 160px;
    }

    .carouselItemText_01 {
        width: 164px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.85);
        text-shadow: 0px 0px 2px rgb(40 41 61 / 4%),
            0px 4px 8px rgb(96 97 112 / 16%);
    }
    .carouselItemText_02 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        color: #000000;

        /* CG Style Guide/Light/Elevation 03 */

        text-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04),
            0px 4px 8px rgba(96, 97, 112, 0.16);
    }
    .carouselItemColor_02 {
        background: rgba(255, 255, 255);
    }
    .menuItem {
        margin-right: 30px;
        margin-top: 30px;
    }
    .allCategoryItems {
        justify-content: flex-start;
    }
    .staticPagesContainer {
        justify-content: center;
        padding: 10px 450px;
        align-items: center;
        gap: 20px;
        color: #4164ab;
    }
    .staticPagesItem {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 164%;
    }
    .carouselAnnimation {
        width: 160px;
    }
    .drawer_title_meta {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        /* identical to box height, or 157% */

        display: flex;
        align-items: center;

        color: #8f90a6;
    }
}
