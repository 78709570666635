html,
body {
    height: auto !important;
    padding: 0;
    margin: 0;
    background: #ebebf0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

:root {
    --container-color: #fafafc;
    --btn-color: linear-gradient(
        147.14deg,
        #5b8def 6.95%,
        #5b8def 6.96%,
        #0063f7 93.05%
    );
    --login-heading-color: #5b8def;
    --heading-color: #555770;
    --label-color: #8f90a6;
    --font-family: Helvetica;
    --input-border-color: #e4e4eb;
    --error: #e53535;
    --white-color: #ffffff;
    --login-input-border-color: #5b8def;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}
.demo_video {
    width: 65%;
    margin: auto;
}
.container {
    background-color: var(--container-color);
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 30px auto 0px auto;
    flex-grow: 1;
    align-items: center;
    padding: 20px 0;
    box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04),
        0px 4px 8px rgba(96, 97, 112, 0.16);
    border-radius: 10px 10px 0px 0px;
    height: calc(100vh - 70px);
    box-sizing: border-box;
}

.page-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
}

.bottomItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    justify-content: space-evenly;
}

.heading {
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: var(--heading-color);
}

.input-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.dropdown-loader {
    height: 30px;
}

.fadeContainer {
    opacity: 0.3;
    pointer-events: none;
}

.radioContainer {
    display: flex;
    flex-direction: column;
    width: 90%;
}
.rccs__card--front,
.rccs__card--back {
    background: linear-gradient(
        180deg,
        #1646cc 3.84%,
        #618afb 88.79%
    ) !important;
    border-radius: 8px !important;
}
.paymentForm {
    display: flex;
    flex-direction: column;
    background: #fafafc;
    box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04),
        0px 4px 8px rgba(96, 97, 112, 0.16);
    border-radius: 8px;
    height: 200px;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    padding: 10px;
}

.paymentForm input {
    height: 34px;
    background: #f2f2f5;
    border: 0.5px solid #c7c9d9;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 24px;
}
.paymentForm input:focus {
    outline: none;
}

.paymentForm .fullInput {
    width: 100%;
    padding-left: 10px;
}
.halfInputContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.halfInputContainer .halfInput {
    width: 100%;
    padding: 10px;
}
.paymentInputLabel {
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 24px;
    color: var(--heading-color);
}
.paymentInputContainer {
    width: 90%;
}
.halfInputContainer .paymentInputContainer {
    width: 40%;
}
.paymentOptions {
    width: 90%;
}
.upiContinaer {
    height: 100px;
}
.gradient-background-0 {
    background: linear-gradient(145.51deg, #ac5dd9 7.21%, #004fc4 94.47%);
}

.gradient-background-1 {
    background: linear-gradient(147.14deg, #ff3b3b 6.95%, #6600cc 93.05%);
}
.gradient-background-2 {
    background: linear-gradient(145.51deg, #fddd48 7.21%, #00b7c4 94.47%);
}

.gradient-background-3 {
    background: linear-gradient(147.14deg, #3e7bfa 6.95%, #6600cc 93.05%);
}

.gradient-background-4 {
    background: linear-gradient(147.14deg, #ff8800 6.95%, #e63535 93.05%);
}

.gradient-background-5 {
    background: linear-gradient(147.14deg, #73dfe7 6.95%, #0063f7 93.05%);
}
.gradient-background-6 {
    background: linear-gradient(145.51deg, #ac5dd9 7.21%, #004fc4 94.47%);
}

.gradient-background-7 {
    background: linear-gradient(147.14deg, #ff3b3b 6.95%, #6600cc 93.05%);
}

.itemContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
}
.itemLabel {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: var(--label-color);
    padding-bottom: 12px;
    text-transform: capitalize;
}
.payment-details {
    display: flex;
    width: 90%;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #555770;
    padding-top: 10px;
}
.payment-text {
    width: 70%;
}
.download-text {
    width: 90%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(83, 83, 83, 0.85);
    word-wrap: break-word;
}
.download-text:hover {
    cursor: pointer;
}
.success-heading {
    color: #38c172 !important;
}
.payment-details-information {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.error {
    color: var(--error) !important;
}
.payment-meta-info {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(83, 83, 83, 0.85);
}

.whiteText {
    color: var(--white-color) !important;
}

.success-screen {
    color: #555770;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
}

.success-text {
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #555770;
}

.seperator-border {
    border-bottom: 1px solid #f2f2f5;
}
.metaData {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
}
.width-90 {
    width: 90%;
}
.padding-tb-20 {
    padding: 20px 0;
}
.solid-border {
    border-bottom: 5px solid #f2f2f5;
}

.payment-method {
    background: #f2f2f5 !important;
    width: 100% !important;
    margin-top: 0 !important;
    overflow: scroll;
    height: auto !important;
}
.bill-info-container {
    width: 90%;
    padding: 0px 0px 16px 20px;
}
.bill-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #e4e4eb;
    padding: 2px;
}
.bill-info-item {
    display: flex;
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #555770;
}
.bill-info-heading {
    color: #1a3281;
    padding-right: 5px;
}

.credit-card-icon {
    position: absolute;
    right: 5px;
    top: 10.5px;
}
.card-num-container {
    position: relative;
}

.loading-btn {
    width: 40px;
}
.cashfreeiframe {
    height: 100vh;
    width: 100vw;
}

.payments-item-icon {
    padding-right: 10px;
}

.login-layout {
    background-color: #e5e5e5;
}
.login-container {
    margin-bottom: 30px;
    border-radius: 10px 10px 10px 10px;
}

.login-heading {
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: var(--login-heading-color);
    height: 75px;
}
.login-btn {
    width: 225px !important;
}

.login-img-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60%;
}

.login-action {
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.inline-order-info {
    display: flex;
    width: 100%;
}

.inline-order-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.inline-order-label {
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #3e7bfa;
}
.inline-order-label:hover {
    cursor: pointer;
}
.inline-order-amount {
    font-weight: bold;
    font-size: 25px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #555770;
}
.no-padding {
    padding: 0px 0px 0px 0px !important;
}
.top-20-padding {
    padding: 20px 0px 0px 0px !important;
}
.tnc {
    overflow: scroll !important;
    padding: 15px !important;
    align-items: flex-start;
    font-family: Roboto;
    font-style: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(83, 83, 83, 0.85);
}

.tnc a {
    color: -webkit-link;
    cursor: pointer;
    display: contents;
}
.tnc-anchor {
    width: 100%;
    text-align: center;
    color: #6698fa;
    cursor: pointer;
    text-decoration: none;
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 24px;
}

.contact-us-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
}

.contact-us-item-img {
    width: 20px;
}
.contact-detail {
    width: 90%;
}
.tnc-static-pages {
    display: flex;
    flex-direction: column;
    color: #6698fa;
    width: 100%;
    align-items: center;
    position: absolute;
    bottom: 125px;
    z-index: 1000;
}
.bold-content {
    font-weight: bold;
}
.overflow-scroll-y {
    overflow-y: scroll !important;
}
.loader-gif {
    width: 156px;
}
.loading-text {
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    position: absolute;
    top: 70px;
}
.deeplink-chars-gif {
    height: 256.73px;
    width: 226.77px;
}
.broken-link-container {
    display: flex;
    justify-content: center;
    position: relative;
}
.broken-link {
    width: 107px;
    height: 107px;
    position: absolute;
    left: 65px;
}
.broken-link-meta {
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    padding-top: 20px;
    color: #555770;
}
.broken-link-heading {
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    color: #3e7bfa;
    position: absolute;
    font-size: 36px;
    line-height: 18px;
    top: -12px;
}
.deeplink-screen {
    justify-content: center !important;
}
.loading-container {
    display: flex;
    justify-content: center;
    position: relative;
}
.ant-drawer-content-wrapper {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.47) 0%,
        rgba(255, 255, 255, 0.47) 0%,
        rgba(222, 222, 255, 0.47) 76.13%
    ) !important;
    border-radius: 14.25px !important;
}
.ant-drawer-content {
    border-radius: 14.25px 14.25px 0 0 !important;
}
.ant-drawer-wrapper-body {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.47) 0%,
        rgba(255, 255, 255, 0.47) 0%,
        rgba(222, 222, 255, 0.47) 76.13%
    ) !important;
}

.ant-modal-body {
    display: flex;
    justify-content: center;
    padding: 80px 48px !important;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.47) 0%,
        rgba(255, 255, 255, 0.47) 0%,
        rgba(222, 222, 255, 0.47) 76.13%
    );
    border-radius: 14.25px !important;
}
.ant-modal-content {
    border-radius: 14.25px !important;
}
.ant-modal-mask {
    background: #e7e7ff !important;
    opacity: 0.7;
    backdrop-filter: blur(8px);
}
.confirmation-modal {
    top: 35% !important;
    width: 340px !important;
}

.confirmation-modal .ant-modal-body {
    background: #ffffff;
    border-radius: 14.25px;
    justify-content: flex-start !important;
    padding: 16px !important;
}
.confirmation-modal .ant-modal-content {
    border-radius: 14.25px;
}
.ant-form-item {
    margin-bottom: 0px !important;
}
.ant-carousel {
    margin-top: 42px !important;
}
.billzy_logo {
    width: 113px;
    height: 45.23px;
}

.company_logo {
    width: 91px;
    height: 51px;
}

.backarrow_logo {
    width: 8.5px;
    margin-left: 3px;
}
.backarrow_logo:hover {
    cursor: pointer;
}
.ant-select-selector {
    box-shadow: 0px 4px 4px #ededff !important;
    border-radius: 6px !important;
    border: 0px !important;
}

.inputLabel {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #535353;
    margin-bottom: 8px;
}
.inputLabelDisabled {
    color: rgba(83, 83, 83, 0.45);
}

.ant-select {
    margin-bottom: 10px !important;
}
.inputWithLabel {
    margin-bottom: 10px;
}
.bottom-actons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 40px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 5px 10px !important;
}

.amount-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
}
.billInfo-collapse {
    background: #ffffff !important;
    border-radius: 6px !important;
    /* width: 380px !important; */
    margin-bottom: 12px !important;
    width: 100%;
}
.variable-payment-note {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    gap: 5px;
    color: #1890ff;
}
.note-text {
    color: rgba(83, 83, 83, 0.45);
}
.pensil-absolute {
    position: absolute;
    top: 45px;
    width: 14.29px;
    right: 15px;
}
.collapse-wrapper {
    position: relative;
}
.billInfo-collapse .ant-collapse-content {
    border-radius: 0 0 6px 6px !important;
    padding: 17px 9px !important;
}
.amount-box {
    width: 114.2px;
    height: 51.7px;
    border-radius: 4.25581px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid #e4e4eb;
}
.amount-box-selected {
    color: #ffffff;
    background: #4164ab;
    box-shadow: -4.75px 11px 30px rgba(35, 63, 120, 0.25);
    cursor: pointer;
}
.success-notification-style .ant-notification-notice-message {
    font-size: 14px !important;
}
.success-notification-style,
.notification-style-with-title {
    border-radius: 7px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto !important;
}
.notification-style-with-title .ant-notification-notice-message,
.custom-message {
    font-weight: 500 !important;
    color: #535353;
    font-size: 16px;
    padding-bottom: 10px;
}
.ant-notification-notice-icon {
    font-size: inherit !important;
}

.notification-style-with-title .ant-notification-notice-description {
    color: rgba(83, 83, 83, 0.85);
}

.success-notification-style .ant-notification-notice-message {
    display: none !important;
}
.ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
    margin-right: 22px;
}
.notification-style-with-title .ant-notification-notice-message {
    display: none !important;
}

.amount-box-delected {
    color: rgba(83, 83, 83, 0.85);

    opacity: 0.8;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.47) 0%,
        rgba(255, 255, 255, 0.47) 0%,
        rgba(222, 222, 255, 0.47) 76.13%
    );
    box-shadow: inset 0px 1.4186px 1.4186px #f2f2f5;
    cursor: pointer;
}
.title-deselected {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 8.51163px;
    line-height: 16px;
    color: rgba(83, 83, 83, 0.45);
    opacity: 0.8;
    color: rgba(83, 83, 83, 0.45);
}
.min-max-container {
    display: flex;
    flex-direction: row;
    margin-top: 21px;
    justify-content: space-evenly;
}
.ant-input-group {
    height: 40px !important;
}
.meta-info-variable-amount {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: rgba(83, 83, 83, 0.65);
    margin-top: 10px;
    text-align: center;
}
.original-amount {
    width: 356px;
    height: 18px;
    left: 44px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(83, 83, 83, 0.85);
    display: flex;
    align-items: center;
    gap: 12px;
}
.variable-inputbox {
    width: 343px;
    height: 40px;
    box-shadow: 0px 0px 0px 2px rgba(24, 144, 255, 0.2);
    border-radius: 6px;
}
.variable-inputbox:focus {
    width: 343px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #40a9ff;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 2px rgba(24, 144, 255, 0.2);
    border-radius: 6px;
}
.enter-amount-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(83, 83, 83, 0.85);
    margin-top: 20px;
}
.extra-info-text {
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.85);
    padding-bottom: 16px;
    padding-left: 24px;
}
.payment-info-container {
    background: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(124.66px);
    border-radius: 14.25px;
    padding: 18px 24px;
    margin-top: 20px;
}
.complete {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #4164ab;
}
.processing {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
}
.error {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #ff5c5c;
}
.payment-info-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 15px;
    color: #4164ab;
}
.payment-status-heading {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #535353;
    padding-bottom: 12px;
}
.sucess-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 700px;
    justify-content: center;
}
.download-item {
    display: flex;
    justify-content: space-between;
}
.width70 {
    width: 70%;
}

.transction-item {
    display: grid;
    grid-template-columns: 0.2fr 1fr 0.5fr;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    background: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(124.66px);
    border-radius: 14.25px;
    width: 100%;
    min-height: 82px;
    padding: 10px 15px;
    cursor: pointer;
    margin-bottom: 16px;
}

.transction-item .transction-icon {
    width: 35px;
    height: 35px;
    background: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}
.amount-selected {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
}
.failure-container {
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.crossfailureIcon {
    width: 70px;
}
.failure-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #535353;
    width: 319px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.failure-title {
    margin-top: 29px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #535353;
    margin-bottom: 48px;
}
.amount {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: rgba(83, 83, 83, 0.85);
}

.amount-selected {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
}

.amount-val {
    font-size: 12px;
    color: rgba(83, 83, 83, 0.85);
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

.horizontal {
    width: 90%;
    opacity: 0.35;
    margin: 2px;
}
.amount-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 8.51163px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.85);
}
.payment-icons {
    width: 101px;
    height: 90px;
    box-shadow: 0px 4.75px 17.81px #eaeaff;
    backdrop-filter: blur(124.66px);
    cursor: pointer;
}
.paymentMethodContainer {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 28px;
    gap: 20px;
}
.successGif {
    width: 132px;
    margin-bottom: 20px;
}
.rtn-home {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-left: 17px;
}
.logo {
    display: flex;
}
.transction-date {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
    display: flex;
    align-items: center;
    gap: 3px;
    margin-right: 16px;
}

.transction-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4164ab;
}

.transction-amount {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    color: #4164ab;
}

.transction-status-failed,
.transction-status-refund-failed {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    background: radial-gradient(
        131.25% 131.25% at 0% -12.5%,
        #ffa39d 0%,
        #ff675e 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-shadow: 0px 2.37px 10.68px rgba(243, 77, 41, 0.25);
    text-transform: capitalize;
    text-align: right;
}

.transction-status-pending,
.transction-status-refund-pending {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    background: radial-gradient(
        117.97% 117.97% at 0% -9.38%,
        #fff282 0%,
        #fc7e24 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-transform: capitalize;
    text-shadow: 0px 2.37px 10.68px rgba(238, 188, 12, 0.25);
    text-align: right;
}

.transction-status-success,
.transction-status-refund-success {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #38c172;
    text-shadow: 0px 2.37px 10.68px rgba(131, 206, 9, 0.25);
    text-transform: capitalize;
    text-align: right;
}

.transaction-detail-item {
    display: flex;
    flex-direction: column;
    gap: 6px;
    background: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(124.66px);
    border-radius: 14.25px;
    width: 100%;
    min-height: 82px;
    padding: 10px 15px;
    justify-content: center;
    margin-bottom: 16px;
}

.transaction-detail-subitem {
    display: grid;
    grid-template-columns: 0.2fr 1fr 0.5fr;
    align-items: center;
    justify-content: space-between;
}

.transaction-detail-payagain {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #1890ff;
    margin: 0;
    text-align: right;
    cursor: pointer;
}

.transaction-detail-order {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 82px;
    background: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(124.66px);
    border-radius: 14.25px;
    padding: 10px 20px;
    flex-wrap: wrap;
    margin-bottom: 16px;
}

.transaction-detail-order-removeTopRadius {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 82px;
    background: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(124.66px);
    border-radius: 14.25px 14.25px 0px 0px;
    padding: 10px 20px;
    flex-wrap: wrap;
}

.transaction-detail-sub-order {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.order-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(83, 83, 83, 0.85);
    margin: 0;
}

.transaction-detail-small-cards {
    width: 100%;
    min-height: 56px;
    background: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(124.66px);
    border-radius: 14.25px;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 16px;
}

.user-outlined {
    padding-right: 10px;
}
.ant-form-item-explain {
    min-height: 0px !important;
}
.inline-action-btn {
    background: rgba(255, 255, 255, 0.85);
    box-shadow: 0px 4px 4px #efefff;
    backdrop-filter: blur(124.66px);
    border-radius: 14.25px;
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    color: rgba(83, 83, 83, 0.65);
}
.inline-action-btn-wrapper :hover {
    cursor: pointer;
}
.or-seperator {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 18px 0 18px 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 125.5%;
    color: #555770;
}
.pd-7px {
    padding: 0 7px 0 0;
}
.desktop-input {
    width: 913px;
}
#theAnchor,
#theAnchorUpi {
    opacity: 0;
    position: absolute;
}
.qr-code-heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 21.7615px;
    line-height: 34px;
    display: flex;
    align-items: center;
    text-align: center;
    color: rgba(83, 83, 83, 0.85);
    margin-bottom: 15px;
}
.qr-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.qr-code-meta {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 21.7615px;
    line-height: 34px;
    display: flex;
    align-items: center;
    text-align: center;
    color: rgba(83, 83, 83, 0.85);
    margin-top: 15px;
    margin-bottom: 50px;
}
.qr-code {
    margin-bottom: 30px;
}
.upi-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.ant-tabs-tab-btn {
    color: rgba(83, 83, 83, 0.85);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-transform: capitalize;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #4164ab !important;
    text-transform: capitalize;
}

.ant-tabs-tab.ant-tabs-tab-active {
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
        0px 2px 4px rgba(96, 97, 112, 0.16);
}

.transactionFilter .ant-drawer-body {
    padding: 0 !important;
}
.transactionFilter .ant-tabs-content-holder {
    height: 100vh;
    background: #fff !important;
}
.ant-tabs-ink-bar {
    position: relative !important;
    background-color: transparent !important;
}

.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
    border-left: 0px !important;
}

.ant-tabs-left
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane,
.ant-tabs-left
    > div
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding-top: 10px !important;
}

.ant-tabs {
    height: 100%;
}
.ant-tabs-content {
    background-color: #fff;
    height: -webkit-fill-available;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #4164ab !important;
    border-color: #4164ab !important;
}

.collapsible-container {
    width: 100%;
    background: rgba(255, 255, 255, 0.45);
    backdrop-filter: blur(124.66px);
    border-radius: 14.25px;
    padding: 15px 20px;
    display: grid;
    grid-template-columns: 0.1fr 0.9fr;
    cursor: pointer;
    gap: 20px;
}

.collapsible-right-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.collapsible-right-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
}

.collapsible-container-items {
    display: flex;
    align-items: center;
    gap: 8px;
}

.collapsible-container-subItems {
    display: grid;
}

.ellipsisText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
}

.loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #8082ed;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    align-self: center;
    margin-top: 130px;
}

.loader-small {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #8082ed;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    align-self: flex-end;
    margin-top: 10px;
}

.raiseComplaint
    .ant-row
    .ant-col
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .ant-select {
    width: 100% !important;
}

.notifyComplaint {
    margin: 0;
    padding: 0px;
    color: #c89e00;
    font-size: 8px;
    padding-left: 8px;
}
/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.button-wrapper-paymentSuccess {
    display: flex;
    justify-content: center;
    width: 100%;
}
.skip-now-action {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125.5%;
    text-align: center;
    color: #4164ab;
    float: right;
}
.skip-now-text {
    margin-right: 5px;
}

.skip-now-action:hover {
    cursor: pointer;
}
.skip-next-icon {
    width: 6px;
    height: 12px;
}
.star-feedback-action {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-top: 30px;
}
.feedback-tag-line {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 125.5%;
    text-align: center;
    color: #535353;
    padding-top: 24px;
}
.feedback-star {
    color: #535353;
    opacity: 0.65;
}
.star-hovered {
    color: #ffd330;
    opacity: 0.7;
}

.feedback-star:hover {
    cursor: pointer;
}
.star-container {
    width: 264px;
    display: flex;
    justify-content: space-between;
    margin-top: 42px;
}
.star-clicked {
    color: #ffd330;
}
.feedback-success-gif {
    width: 122px;
}
textarea:focus,
input:focus {
    outline: none;
}
.feedback-meta-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125.5%;
    text-align: center;
    color: rgba(85, 87, 112, 0.65);
    padding-top: 8px;
}
.feedback-error {
    color: #ff4d4f;
}

.feedback-wrapper {
    width: 90%;
    margin-top: 40px;
    margin-bottom: 40px;
}

.feedback-modal .ant-modal-body {
    padding: 20px 28px 80px 28px !important;
    height: 368px !important;
}
.question-modal .ant-modal-body {
    padding: 20px 28px 80px 28px !important;
    height: 549px !important;
}

.faq {
    width: 100%;
}

.faq-parent-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.45);
    border: 1px solid #ffffff;
}

.faq-collpase {
    background: transparent !important;
    width: 100% !important;
}

.faq-collpase .ant-collapse-item .ant-collapse-header {
    color: #4164ab !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px !important;
    border-bottom: 1px solid #ffffff;
}

.faq-collpase .ant-collapse-content-box {
    color: rgba(83, 83, 83, 0.85);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border-bottom: 1px solid #ffffff;
}

.faq-collpase .ant-collapse-content-box .actionable {
    cursor: pointer;
    color: #1890ff;
}

.faq-feedback-wrapper {
    display: flex;
    gap: 10%;
}

.faq-feedback-subContainer {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    align-items: center;
    cursor: pointer;
}

.faq-button-wrapper {
    width: 85px;
    height: 30px;
    align-self: flex-end;
    margin-top: 15px;
}

.footerWrapper {
    padding-bottom: 60px;
}

.footer {
    background: transparent;
    text-align: center;
    position: absolute;
    bottom: 20px;
    width: 95%;
    margin: auto;
}

.nonVariableAmountBifurcation {
    width: 98%;
}

.upDownChevron {
    width: 12px;
    height: 12px;
    cursor: pointer;
}

.pageContainer {
    width: 100%;
}
.pwa-install-drawer .ant-drawer-close {
    position: absolute;
    top: 15px;
    right: 15px;
}
.pwa-meta-tag {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125.5%;
    color: #555770;
}

.pwa-drawer-icon {
    margin: 16px auto;
    margin-bottom: 40px;
}

.ant-tree {
    background: rgba(255, 255, 255, 0.45) !important;
    padding: 24px !important;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: #4164ab !important;
    border-color: #4164ab !important;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: #4164ab !important;
}

.ant-tree .ant-tree-node-content-wrapper {
    color: #535353 !important;
}

.ant-tree .ant-tree-treenode,
.ant-tree .ant-tree-node-content-wrapper {
    width: 100% !important;
}

@media screen and (min-width: 992px) {
    .pageContainerPaymentsMethods {
        height: 560px;
        overflow: scroll;
    }
    .pageContainer {
        width: 70%;
        margin-bottom: 10px;
    }
    .faq {
        width: 75%;
    }
    .ant-drawer-content {
        border-radius: 0px !important;
    }
    .paymentMethodContainer {
        width: 400px;
    }
    .tnc-static-pages {
        bottom: 100px;
    }
    .container {
        width: 95%;
        padding: 65px 0px 10px 0px;
        height: calc(100vh - 134px);
    }
    .heading {
        font-size: 30px;
        line-height: 24px;
    }
    .drop-down-icon {
        height: 10.7px;
    }
    .bottomItems img {
        width: 384px;
    }
    .loading-btn {
        width: 100px !important;
    }

    .radioContainer {
        flex-direction: row;
    }

    .payment-method {
        padding: 10px 0px 10px 0px !important;
    }
    .paymentInputLabel {
        font-size: 15px;
        padding-bottom: 5px;
    }
    .paymentForm input {
        font-size: 15px;
        padding-bottom: 5px;
    }
    .paymentForm {
        height: 220px;
    }
    .payment-details {
        padding-top: 25px;
        font-size: 20px;
    }

    .tnc-anchor {
        width: 100%;
        text-align: center;
        color: #6698fa;
        cursor: pointer;
        text-decoration: none;
        font-family: Helvetica;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
    }
    .tnc-anchor :hover {
        cursor: pointer;
    }
    .tnc {
        font-size: 20px;
        line-height: 30px;
    }
    .contact-us-item {
        justify-content: flex-start;
        gap: 30px;
    }
    .contact-us-item img {
        width: 25px;
    }
    .contact-detail {
        padding-left: 10px;
    }
    .deeplink-chars-gif {
        width: 581px;
        height: 552.72px;
    }
    .broken-link {
        width: 274px;
        height: 274px;
        position: absolute;
        left: 145px;
    }
    .broken-link-heading {
        font-size: 72px;
        line-height: 18px;
    }
    .broken-link-meta {
        font-size: 30px;
        line-height: 18px;
    }
    .loader-gif {
        width: 405px;
    }
    .loading-text {
        top: 200px;
        font-size: 30px;
        line-height: 18px;
    }
    .bill-info-item {
        font-size: 20px;
        padding-bottom: 25px;
    }
    .bill-info-container {
        width: 90%;
        padding: 16px 20px;
    }
    .desktop-width-973 {
        width: 973px;
    }
    .backarrow_logo {
        width: 12px;
        margin-left: 3px;
    }
    .ant-select {
        margin-bottom: 10px !important;
    }
    .bottom-actons-wrapper {
        display: block;
    }
    .payment-info-desktop {
        width: 910px;
    }
    .successGif {
        width: 264px;
    }
    .ant-drawer-content {
        border-radius: 14.25px 0 0 14.25px !important;
    }
    .success-text {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        display: flex;
        align-items: center;
        text-align: center;
        color: rgba(0, 0, 0, 0.85);
    }
    .transction-item {
        grid-template-columns: 0.2fr 2.5fr 0.5fr;
    }
    .transaction-detail-item {
        padding: 10px 20px;
    }

    .transaction-detail-subitem {
        grid-template-columns: 0.2fr 2.5fr 0.5fr;
    }
    .inline-action-btn {
        align-items: center;
    }
    .inline-btn-meta {
        width: 800px;
    }
    .inline-action-title {
        color: #4164ab;
    }
    .or-seperator {
        width: 913px;
        color: #535353;
    }

    .collapsible-container {
        grid-template-columns: 0.05fr 0.95fr;
    }

    .collapsible-container-items {
        gap: 15px;
    }

    .ellipsisText {
        white-space: inherit;

        width: 100%;
    }
    .pensil-absolute {
        top: 45px;
    }
    .amount-box:hover {
        cursor: pointer;
    }
    .min-max-container {
        width: 250px;
        margin: 20px auto;
    }
    .variable-inputbox {
        width: 871px;
    }
    .variable-inputbox:focus {
        width: 871px;
    }

    .button-wrapper-paymentSuccess {
        justify-content: flex-start;
    }
    .skip-now-action {
        margin-right: -25px;
    }

    .notifyComplaint {
        font-size: 10px;
    }

    .nonVariableAmountBifurcation {
        width: 68.5%;
    }
}
@media screen and (max-width: 990px) {
    .demo_video {
        width: 90%;
    }
    .pageContainerPaymentsMethods {
        height: 600px;
    }
}
.static-pages {
    display: flex;
    width: 90%;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #535353;
    padding-top: 10px;
}
