.hamburgerWrapper{
    background: #F3F3F3;
    opacity: 0.7;
    box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
    border-radius: 8px;
    width: 54px;
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}